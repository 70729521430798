<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Kontakt</h1>
      </v-col>
      <v-col  class="d-flex justify-end align-end" align-self="end" align="end">
        <v-btn icon to="/">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <TrumpIpsum lines="5"></TrumpIpsum>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrumpIpsum from "../components/TrumpIpsum";
export default {
  name: "Contact",
  components: {TrumpIpsum}
}
</script>

<style scoped>

</style>